import { render, staticRenderFns } from "./AdminVolunteers.vue?vue&type=template&id=712b2649&scoped=true&"
import script from "./AdminVolunteers.vue?vue&type=script&lang=js&"
export * from "./AdminVolunteers.vue?vue&type=script&lang=js&"
import style0 from "./AdminVolunteers.vue?vue&type=style&index=0&id=712b2649&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "712b2649",
  null
  
)

export default component.exports