<template>
  <v-container class="pt-0 pb-15">
    <div style="display: flex; align-items: center;">
      <span class="page-title">Volunteers</span>
      <div class="title-line ml-3" />
    </div>
    <v-data-table
      :headers="headers"
      :items="volunteers"
      :items-per-page="15"
      :loading="loading"
      @click:row="onSelectClient"
      class="elevation-0"
      show-expand
      :expanded.sync="expanded"
      :single-expand="true"
      item-key="_id"
    >
      <template v-slot:[`item.image`]="{ item }">
        <div @click="goToProfile(item)">
          <editable-avatar
            :image="item.thumbnail ? item.thumbnail : item.image"
            :editable="false"
            :size="50"
            class="my-5"
          />
        </div>
      </template>
      <template v-slot:[`item.info`]="{ item }">
        <div class="url-button" @click="goToProfile(item)">
          {{ item.first_name }} {{ item.last_name }}
        </div>
        <div>
          {{ formatPhoneNumber(item.phone) }}
          <v-tooltip bottom v-if="item.phone_verified">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                >mdi-check-circle</v-icon
              >
            </template>
            Phone verified
          </v-tooltip>
        </div>
        <div>
          {{ item.email }}
          <v-tooltip bottom v-if="item.email_verified">
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                >mdi-check-circle</v-icon
              >
            </template>
            Email verified
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        <div>
          <div>
            {{ item.address
            }}{{ item.address2 ? ", " + item.address2 + "" : "" }}
          </div>
          <div>{{ item.city }}, {{ item.state }} {{ item.zip }}</div>
        </div>
      </template>
      <template v-slot:[`item.about_me`]="{ item }">
        <div class="text-block">{{ item.about_me }}</div>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        <div>{{ formatDate(item.createdAt) }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                small
                class="ml-2"
                @click.stop="onEditVolunteer(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            Edit
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
                small
                class="ml-2"
                @click.stop="onDeleteVolunteer(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="#6038F7" style="margin-top: 2px;" size="18"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            Delete
          </v-tooltip>
        </div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" class="px-0">
          <div class="ma-3 d-flex flex-row">
            <div>
              <span class="volunteer-subtitle" v-if="item.bgImage"
                >Background</span
              >
              <div style="width: 300px; max-height: 83px;" v-if="item.bgImage">
                <v-img :src="item.bgImage" width="300" max-height="83" />
              </div>
            </div>
            <div class="ml-3" style="max-width: 126px;">
              <span class="volunteer-subtitle mt-3">Causes</span>
              <div class="d-flex flex-wrap">
                <site-icon
                  :name="icon"
                  type="Cause"
                  v-for="(icon, key) in item.causes"
                  :key="key"
                  :size="30"
                  class="mr-3 mt-2"
                />
              </div>
            </div>
            <div class="ml-3" style="max-width: 126px;">
              <span class="volunteer-subtitle mt-3">Interests</span>
              <div class="d-flex flex-wrap">
                <site-icon
                  :name="icon"
                  type="Interest"
                  v-for="(icon, key) in item.interests"
                  :key="key"
                  :size="30"
                  class="mr-3 mt-2"
                />
              </div>
            </div>
            <div class="d-flex ml-3">
              <div>
                <span class="volunteer-subtitle">Availabilities</span>
                <div class="d-flex mt-2 flex-column">
                  <div
                    v-for="availability in item.availabilities"
                    :key="availability"
                    class="mb-3 d-flex align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          color="#6038f7"
                          size="14"
                          >mdi-calendar-clock</v-icon
                        >
                      </template>
                      <span>Available time</span>
                    </v-tooltip>
                    <div class="app-bold-font">
                      {{ getDay(availability) }}
                    </div>
                    <span class="ml-3 font-16">
                      {{ getScheduleTime(availability) }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="ml-15">
                <span class="volunteer-subtitle">Information</span>
                <div class="d-flex align-center mt-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        color="#6038f7"
                        size="14"
                      >
                        mdi-account-group
                      </v-icon>
                    </template>
                    <span>Age group</span>
                  </v-tooltip>
                  {{ item.age_group }}
                </div>
              </div>
              <div class="ml-15">
                <span class="volunteer-subtitle">Followers</span>
                <div class="d-flex flex-column mt-1">
                  <span>{{ item.fanned.length }} Agencies</span>
                  <span>{{ item.followers.length }} Volunteers</span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </template>
    </v-data-table>
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseConfirmDelete"
      :onConfirm="onConfirmDelete"
    />
    <admin-edit-volunteer-dialog
      :dialog="editDialog"
      :onClose="onCloseEditDialog"
      :volunteer="selected"
      v-if="editDialog && selected"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import dateFormat from "dateformat";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import AdminEditVolunteerDialog from "./volunteer/AdminEditVolunteerDialog.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: {
    EditableAvatar,
    ConfirmDeleteDialog,
    AdminEditVolunteerDialog,
    SiteIcon,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "image" },
        { text: "Info", value: "info" },
        { text: "Address", value: "address" },
        { text: "About", value: "about_me" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "actions" },
        { text: "", value: "data-table-expand" },
      ],
      deleteDialog: false,
      selected: null,
      expanded: [],
      editDialog: false,
    };
  },
  methods: {
    ...mapActions("volunteer", {
      fetchAllVolunteers: "fetchAllVolunteers",
      deleteVolunteer: "deleteVolunteer",
    }),
    onSelectClient(item) {
      console.log(item);
    },
    formatDate(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "mm/dd/yyyy");
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    goToProfile(item) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id: item._id },
      });
    },
    onEditVolunteer(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onDeleteVolunteer(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.loading = true;
      this.deleteVolunteer({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getDay(schedule) {
      return schedule.split(" ")[0];
    },
    getScheduleTime(schedule) {
      return schedule.substring(4);
    },
  },
  computed: {
    ...mapGetters({
      volunteers: "volunteer/getAllVolunteers",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone",
    }),
  },
  mounted() {
    console.log(this.profileType);
    if (this.profileType != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.loading = true;
    this.fetchAllVolunteers()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.page-title {
  color: #472583;
  font-size: 30px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.volunteer-subtitle {
  color: #472583;
  font-size: 14px;
}
</style>
